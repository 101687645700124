.App {
  text-align: center;
	font-family: 'Open Sans', sans-serif;
	font-size: 25px;
	color: #000000;
	font-weight: normal;
}

.App-logo {
  position: absolute;
  align-items: left;
  margin-left: -5px;
}


.App-logo img{
  width: 0px;
  height: 0px;
  padding-top: 2%;
}

.App-company {
  position: relative;
  top:40px;
  justify-content: center;
}

.App-company img{
  max-width: 485px;
  max-height: 240px;
  width: 90%;
  padding-top: 2%;
}

.App-contactinfo p{
  cursor:pointer;
}

.Fab {
position:fixed;
right:10px;
bottom:10px;
}

.App-header {
  /* The image used */
  background-image: url("HuwelijksfotograafEllen.png");

  /* Full height */
  height: 100vh; 

  /* Center and scale the image nicely */
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  display: grid;
  align-items: center;
  justify-content: center;
}

.App-card {
  max-width: 30em;
  max-height: 13em;
  width: 90vw;
  height: 70vh;
  box-shadow: 0 0 1em 0 rgba(0, 0, 0, .2); 
  border-radius: 5px;
  background-color: rgba(255, 255, 255, .35);
  backdrop-filter: blur(5px);
  padding: 5px;
  margin: 15px;
  padding-bottom: 50px;
  opacity: 0.85;
}

.App-card p{
  position: relative;
  font-size: 21px;
  font-weight: bold;
  text-align: left;
  vertical-align: middle;
  padding-left: 1em;
  width: fit-content;
}


.horizontal-line {
  width: auto;
  border-top: 2px solid black;
}

.App-contactinfo {
  position: relative;
  left:45px;
  width: 80%;
  height: 100%;
  line-height: 50%;
  vertical-align:middle;
}


.App-contactinfo img{
  position: relative;
  width: 45px;
  height: 45px;
  padding-right: 10px;
  margin-top: -12px;
  top: 15px;
}



@media (min-width: 850px) {
  .App-logo img{
    width: 160px;
    height:160px;
  }
}

@media (max-width: 849px) {
  .App-company img{
    position: relative;
    top:-20px;
  }
  .App-card p, .App-company p, .App-contactinfo p{
    margin-top: 0px;
  }
  .App-contactinfo img{
    position: relative;
    top: 15px;
  }
}


@media (max-width: 570px) {
  .App-card p, .App-company p, .App-contactinfo p{
    margin-top: -12px;
    font-size: 16px;
  }
  .App-contactinfo {
    top: 5px;
    line-height: 100%;
  }
  .App-contactinfo img{
    position: relative;
    width: 25px;
    height: 25px;
    padding-right: 5px;
    margin-top: -12px;
    top: 6px;
  }
  .App-company{
    position: relative;
    top: 20px;
    justify-content: center;
  }
  .App-card{
    width: 90vw;
    height: 60vh;
  }

}

@media (max-width: 550px) {
  .App-contactinfo p{
    margin-left:-40px;
  }
}

@media (max-width: 350px) {
  .App-card p, .App-company p, .App-contactinfo p{
    margin-top: 0px;
    font-size: 12px;
  }
  .App-contactinfo {
    top: 5px;
    line-height: 100%;
  }
  .App-contactinfo img{
    position: relative;
    width: 25px;
    height: 25px;
    padding-right: 5px;
    margin-top: -15px;
    top: 7px;
  }
  .App-company{
    position: relative;
    top: 20px;
    justify-content: center;
  }
  .App-card{
    width: 90vw;
    height: 60vh;
  }
}
